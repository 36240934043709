import { render } from "./SingleMassText.vue?vue&type=template&id=20445859"
import script from "./SingleMassText.vue?vue&type=script&lang=js"
export * from "./SingleMassText.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "20445859"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('20445859', script)) {
    api.reload('20445859', script)
  }
  
  module.hot.accept("./SingleMassText.vue?vue&type=template&id=20445859", () => {
    api.rerender('20445859', render)
  })

}

script.__file = "src/components/SingleMassText.vue"

export default script