<template>
  <div>
    <div class="breadcrumb">
      <router-link
        :to="
          user.role == 'superadmin'
            ? `/businesses/${routeParams.business_id}/mass-text-history`
            : `/mass-text/history`
        "
        class="mt-4 inline text-2xl font-semibold text-gray-600"
      >
        Mass Text History
      </router-link>
      <span class="text-base inline font-semibold text-gray-800"
        >/ {{ mass_text.mass_text_title }}
      </span>
    </div>
    <div class="mt-4 bg-gray-700 p-4 text-white text-base">
      Mass Text History Details
    </div>
    <div class="flex flex-col py-7 border-2 border-gray-500">
      <div class="flex justify-between w-full px-3">
        <div>Date:</div>
        <div>
          {{
            mass_text.mass_text_timestamp &&
              transformDate(mass_text.mass_text_timestamp.seconds)
          }}
        </div>
      </div>
      <div class="flex justify-between w-full px-3 mt-4">
        <div class="mr-5">Total Recipients:</div>
        <div class="ml-8">
          {{ mass_text.mass_text_recipients_count }}
        </div>
      </div>
      <div class="flex justify-between w-full px-3 mt-4">
        <div>Title:</div>
        <div class="ml-8">
          {{ mass_text.mass_text_title }}
        </div>
      </div>
      <div class="flex justify-between w-full px-3 mt-4">
        <div>Message:</div>
        <div class="ml-8 text-justify">
          {{ mass_text.mass_text_message }}
        </div>
      </div>
      <div
        class="flex justify-between w-full px-3 mt-4"
        v-if="mass_text.mass_text_attachment"
      >
        <div>Image:</div>
        <div class="ml-8">
          <img :src="url" />
        </div>
      </div>
      <div
        class="flex justify-center flex-col md:flex-row px-2 mt-10"
        v-if="user.role !== 'superadmin'"
      >
        <router-link :to="`/mass-text/compose/${mass_text.id}`">
          <button
            class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-40 md:mr-3 text-center"
            type="button"
            :disabled="isSubmitted"
            :class="isSubmitted ? 'opacity-75' : ''"
          >
            Recreate
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
const firebase = require("../firebaseConfig");
const { DateTime } = require("luxon");

export default {
  data: () => ({
    mass_text: {},
    url: "",
    user: {},
    routeParams: {},
    isSubmitted: false,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  }),
  mounted() {
    this.routeParams = this.$route.params;
    this.getUserInfo();
    if (this.user.role == "superadmin") {
      this.getBusinessInfo();
    }
    this.getSingleMassText();
  },
  methods: {
    getBusinessInfo() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("users")
            .doc(firebase.auth.currentUser.uid)
            .onSnapshot((doc) => {
              resolve({
                id: doc.id,
                ...doc.data(),
              });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    getUserInfo() {
      firebase.db
        .collection("users")
        .doc(firebase.auth.currentUser.uid)
        .onSnapshot((doc) => {
          this.user = {
            uid: doc.id,
            ...doc.data(),
          };
        });
    },
    async getSingleMassText() {
      const business = await this.getBusinessInfo();

      await firebase.db
        .collection("businesses")
        .doc(
          this.user.role === "superadmin"
            ? this.routeParams.business_id
            : business.business_id
        )
        .collection("mass_texts")
        .doc(
          this.user.role === "superadmin"
            ? this.routeParams.mass_textid
            : this.$route.params.single_masstext
        )
        .onSnapshot((doc) => {
          this.mass_text = {
            id: doc.id,
            ...doc.data(),
          };
          const businessId =
            this.user.role === "superadmin"
              ? this.$route.params.business_id
              : business.business_id;

          if (this.mass_text.mass_text_attachment)
            this.loadImage(businessId, this.mass_text.mass_text_attachment);
        });
    },
    transformDate(seconds) {
      let date = new Date(0);
      date.setUTCSeconds(seconds);
      const ISOdate = date.toISOString();

      return DateTime.fromISO(ISOdate)
        .setZone("America/Detroit")
        .toFormat("LLLL dd, yyyy hh:mm a");
    },
    async loadImage(id, bucketPath) {
      const url = await firebase.storage
        .ref(id)
        .child("mass-text")
        .child(bucketPath)
        .getDownloadURL();

      this.url = url;
    },
  },
};
</script>
